<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <div class="page__content">
            <div class="sidebar__header">
              <h4 class="sidebar__title">
                <span style="font-size: 30px">{{material}}</span>
                <br>
                <span style="font-size: 18px;">{{ collector }}</span>
              </h4>
              <div class="buttons">
                <button type="button" class="button_outline button-200" @click="downloadItem()">Imprimir relatório</button>
              </div>
            </div>
            <div class="tabs__flex">
              <div>
              </div>
              <div  class="buttons tabs__search">
                <form class="search__form">
                  <input class="form__input" type="search" placeholder="Pesquisar..." v-model="search" />
                  <button class="button search__button" type="submit">
                    <i class="icon icon-search"></i>
                  </button>
                </form>
              </div>
            </div>

            <div class="tab__body current" id="all">
              <table class="table">
                <thead>
                  <tr>
                    <th>Gerador</th>
                    <th>Cidade</th>
                    <th>Bairro</th>
                    <th>Coleta</th>
                    <th>Quantidade</th>
                    <th>Pago</th>
                    <th>Total</th>
                  </tr>
                </thead>

                <tbody v-if="collects !== null">
                  <tr v-for="collect in pagedCollects" :key="collect.id">
                    <td>
                      <span>{{ collect.generator }}</span>
                    </td>
                    <td>
                      <span>{{ collect.city }}</span>
                    </td>
                    <td>
                      <span>{{ collect.district }}</span>
                    </td>
                    <td>
                      <span>{{ collect.date }}</span>
                    </td>
                    <td>
                      <span>{{ collect.ammount}}</span>
                    </td>
                    <td>
                      <span>{{ collect.paid }}</span>
                    </td>
                    <td>
                      <span>{{ collect.total }}</span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else style="text-align: center;">
                  <loading></loading>
                </tbody>
                <tfoot>
                  <tr style="background-color: gray;color: #FFFFFF;height: 30px;">
                    <th style="text-align: right;" colspan="4">{{collects !== null ? collects.length : 0}} Coletas</th>
                    <th style="padding-left: 25px;">{{ totalAmmount }}</th>
                    <th style="padding-left: 25px;">{{ paidMedia }}</th>
                    <th style="text-align: center;">{{ sumTotal}}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
import _ from 'lodash'
export default {
  data: () => ({
    modal: false,
    loading: false,
    collectors: null,
    editing: {},
    search: '',
    page: 1,
    perPage: 10,
    pages: [],
    filter: '',
    collects: null,
    material: '',
    collector: '',
    totalAmmount: '',
    paidMedia: '',
    sumTotal: ''
  }),
  components: {
    Loading: () => import('../../components/loading.vue')
  },
  methods: {
    downloadItem () {
      const url = '/closeout/report/pdf?id=' + this.$route.query.id

      this.$http.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'acerto'
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    openModal (item = null) {
      console.log('user', item)

      if (item === null) {
        this.editing = {
          id: null,
          zip_code: null,
          district: null,
          complement: null,
          street: null,
          city_id: null,
          province_id: null,
          name: null,
          phone: null,
          cpf: null,
          collector_type: null,
          email: null,
          number: null,
          status: 1
        }
      } else {
        this.editing = {
          id: item.id,
          zip_code: item.address.cep,
          district: item.address.district,
          complement: item.address.complement,
          street: item.address.street,
          city_id: item.address.city_id,
          province_id: item.address.province_id,
          name: item.name,
          phone: item.data.phone,
          cpf: item.type === 'PJ' ? item.data.cnpj : item.data.cpf,
          collector_type: item.company_roles[0].role.id,
          email: item.email,
          number: item.address.number,
          status: item.status
        }
      }
      this.modal = true
    },
    async getData () {
      const response = await axios.get('/closeout/report?id=' + this.$route.query.id)
      console.log('response', response.data.data)
      this.collects = response.data.data.collects
      this.material = response.data.data.material
      this.collector = response.data.data.collector
      this.totalAmmount = response.data.data.total
      this.paidMedia = response.data.data.media
      this.sumTotal = response.data.data.sum
    },
    setPages (value) {
      this.pages = []
      const numberOfPages = Math.ceil(value.length / this.perPage)
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate (value) {
      const page = this.page
      const perPage = this.perPage
      const from = (page * perPage) - perPage
      const to = (page * perPage)
      this.setPages(value)
      return value.slice(from, to)
    },
    async changeUserActivation (value) {
      const confirmActivation = await swal({
        title: value.status === 1 ? 'Desativar usuário' : 'Ativar usuário',
        text: value.status === 1 ? 'Deseja realmente desativar esse usuário?' : 'Deseja realmente ativar esse usuário?',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: null,
            visible: true,
            className: 'swal-button--cancel',
            closeModal: true
          },
          confirm: {
            text: 'Confirmar',
            value: true,
            visible: true,
            className: 'swal-button--confirm',
            closeModal: true
          }
        },
        dangerMode: true
      })

      if (confirmActivation) {
        try {
          value.status = value.status === 1 ? 3 : 1

          const payload = {
            id: value.id,
            zip_code: value.address.cep,
            district: value.address.district,
            complement: value.address.complement,
            street: value.address.street,
            city_id: value.address.city_id,
            province_id: value.address.province_id,
            name: value.name,
            phone: value.data.phone,
            collector_type: value.company_roles[0].role.id,
            email: value.email,
            number: value.address.number,
            status: value.status
          }

          if (value.type === 'PJ') {
            payload.cnpj = value.data.cnpj
          } else {
            payload.cpf = value.data.cpf
          }
          const response = await axios.put('/collectors/users', payload)
          if (response.status === 200) {
            await swal({
              title: value.status === 1 ? 'Usuário ativado com sucesso!' : 'Usuário desativado com sucesso!',
              icon: 'success'
            })
            await this.getData()
          }
        } catch (err) {
          await swal('Oops!', 'Erro ao desativar usuário', 'error')
        }
      }
    }
  },
  computed: {
    pagedCollects: function () {
      let filtered = null
      filtered = this.collects
      if (this.search !== '' && this.search) {
        filtered = _.flatMap(this.collects).filter((value) => {
          return value.generator.includes(this.search) ||
          value.city.includes(this.search) ||
          value.district.includes(this.search) ||
          value.date.includes(this.search)
        })
      }
      return filtered
    }
  },
  async created () {
    await this.getData()
  },
  mounted () {
    this.getData()
  },
  watch: {
    modal (val) {
      if (!val) this.getData()
    },
    'pagination.page' (val, old) {
      if (val === old) return null
      this.getData(val)
    },
    search (val, old) {
    }
  },
  filters: {
    date (val) {
      if (!val) return '-'
      const regex = /(?<year>^\d{4}).(?<month>\d{2}).(?<day>\d{2})/
      const match = regex.exec(val)
      const groups = match.groups
      const { year, month, day } = groups
      return `${day}/${month}/${year}`
    },
    color (value) {
      let color = 'rgb(255, 55, 26)'
      let backgroundColor = 'rgb(255, 230, 224)'

      if (value === 1) {
        color = 'rgb(22, 182, 102)'
        backgroundColor = 'rgb(224, 248, 230)'
      }

      return { color, backgroundColor }
    },
    phone (val) {
      if (val.length !== '') {
        return val.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')
      } else {
        return val
      }
    }
  }
}
</script>

<style>
.sidebar__header {
  display: flex;
  justify-content: space-between;
}
.tabs__search {
  width: 40%;
}

.table {
  table-layout: fixed;
}

th,
td {
  font-weight: 400;
  width: 100%;
  text-align: left;
}

.table thead tr th:nth-last-child(1) {
  text-align: center;
}

.table tbody tr td:nth-last-child(1) {
  text-align: center;
}
.table tbody tr td:nth-last-child(1) button {
  background: transparent;
  margin: 0 5px;
}

td span {
  font-weight: 400;
  font-size: 16px;
}

tr {
  border-bottom: 1px solid #e5e5e5;
}

.table tbody tr td:nth-child(1) {
  width: auto;
}

.table tbody tr td:nth-child(3) {
  text-align: left;
}

.table tbody tr td:nth-child(9) {
  text-align: center;
}

.alert {
  background-color: #fff3e2;
  color: #d87420;
  padding: 12px;
  border-radius: 7px;
}

.alert_card {
  font-size: 10pt;
  text-align: center;
  padding: 3px;
  border-radius: 4px;
}

.active_icon {
  color: #47be71;
}

.loading-user {
  color: #15a548;
  font-weight: bolder;
  font-size: 1.1rem;
  animation: loadingUser 1s linear infinite;
}

@keyframes loadingUser {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading{
  margin: 0 200%;
}
</style>
